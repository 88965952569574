@import "_colors";
@import "_mixins";

.settings-menu {
    margin-top: 1rem;
    .block {
        @include boxShadow;
        &.zoom {
            @include hoverZoom;    
        }
        margin-bottom: 1.5rem;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .title {
            flex: 1 1 auto;
            h2 {
                margin: 0;
            }
        }
        .actions {
            min-width: 20%;
            text-align: center;
        }
    }
}

.users {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    .info {
        flex: 1 1 auto;
        .name {
            font-weight: 700;
            font-size: 1.1rem;
        }
        .role {
            font-size: .9rem;
            color: $grey-medium;
        }
    }
    .actions {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        span, a {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $grey-light;
            width: 30px;
            height: 30px;
            margin-left: 1rem;
            border-radius: 50%;
            font-size: .85rem;
            color: $grey-dark;
            &:hover {
                background: $grey-medium;
                cursor: pointer;
            }
        }
        svg {
            
        }
    }
}

// .product-options {
//     display: flex;
//     flex-direction: column;
//     .product {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         justify-content: center;
//         margin-bottom: 2rem;
//         &:last-child {
//             margin-bottom: 0;
//         }
//         .info {
//             flex: 1 1 0;
//             .badge {
//                 background-color: $brand-primary-dark;
//                 padding: .25rem .5rem;
//                 color: white;
//                 font-size: .9rem;
//                 border-radius: 5px;
//                 margin-left: .25rem;
//             }
//         }
//         .cost {
//             margin-left: 1rem;
//             flex-basis: 100px;
//             width: 100px;
//         }
//         .action {
//             margin-left: 1rem;
//             flex-basis: 100px;
//             width: 100px;
//             .select-btn {
//                 display: flex;
//                 background-color: $grey-medium;
//                 color: white;
//                 align-items: center;
//                 justify-content: center;
//                 border-radius: 5px;
//                 padding: .25rem .5rem;
//                 &:hover {
//                     cursor: pointer;
//                     background-color: $grey-dark;
//                 }
//             }
//         }
//     }
// }
.product-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    .product-info {
        flex: 1 1 auto;
    }

    .action-holder {
        flex: 0 0 250px;
        .active {
            background-color: $brand-primary-dark;
            color: white;
            padding: .5rem 1rem;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center
        }
        .selectable {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-light;
            padding: .5rem 1rem;
            border-radius: 4px;
            &:hover {
                cursor: pointer;
                background-color: $brand-primary-medium;
                color: $brand-primary-dark;
            }
        }
    }
}

.planOptions {
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    .price {
        // background-color: red;
        .info {
            display: flex;
            flex-direction: row;
        }
        // flex-basis: 45%;
        padding: 2rem;
        // border: 1px solid;
        h2 {
            margin-bottom: 0;
            font-weight: 700;
        }
        .tiers {
            font-size: .9rem;
            display: flex;
            justify-content: space-between;
        }
        .selection {
            .btn {
                margin: 2rem auto 1rem auto;
                display: block !important;
            }
        }
    }
}

.StripeElement {
    width: 100%;
    height: 36px;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    margin-right: 8px;
    border-radius: .25rem;
    margin-bottom: 1.5rem;
}

.price-quantity {
    display: flex;
    flex-direction: row;
    .quantity {
        display: flex;
        flex-direction: row;
        flex-basis: 40%;
        .control {
            width: 35px;
            color: $grey-medium;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .value {
            display: flex;
            font-size: 3rem;
            justify-content: center;
            align-items: center;
            min-width: 40px;
            // flex: 1 1 auto;
        }
        .label {
            display: flex;
            font-size: 2rem;
            justify-content: flex-start;
            align-items: center;
            flex: 1 1 auto;
            padding-left: 15px;
        }
    }
    .price {
        // flex-basis: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .display {
            font-size: 1.33rem;
            line-height: 1.2;
            flex: 1 1 auto;
            span {
                font-size: 1.5rem;
            }
        }
        .control {

        }
    }
}

.new-plan-label {
    text-transform: uppercase;
    font-weight: 300;
    color: $grey-medium;
}

.plans {
    .plan {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        .info {
            flex: 1 1 auto;
            span {
                font-weight: 700;
                font-size: 1.15rem;
            }
        }
        .details {
            display: flex;
            flex-direction: row;
            .detail {
                display: flex;
                flex-direction: column;
                padding: .25rem;
                margin: 0 .5rem;
                align-items: center;
                justify-content: center;
                .count {
                    font-size: 1.75rem;
                    line-height: 1.5rem;
                }
                .type {
                    color: $grey-dark;
                    text-transform: uppercase;
                    font-size: .8rem;
                }
            }
        }
        .action {
            margin: 0 1rem;
        }
    }
}