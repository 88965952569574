// required for bootstrap
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// optional bootstrap
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/tables";

@import "_breakpoints";
@import "_colors";
@import "_mixins";

body {
    color: $grey-dark;
    font-size: 16px;
}


h2 {
    font-size: 1.25rem;
    font-weight: 600;
}

#app {
    margin-top: 52px;
    .app-content {
        min-height: calc(100vh - 87px);
    }
}

.legalize {
    margin-top: 2rem;
    h1 {
        font-size: 1.5rem;
        line-height: 1;
    }
    h2 {
        font-size: 1.25rem;
        text-decoration: underline;
        font-weight: 600;
    }
    h3 {
        font-size: 1.1rem;
        font-style: italic;
        font-weight: 600;
    }
    ol {
        list-style: alpha-lower;
        margin-bottom: 1rem;
        li {
            padding-bottom: .5rem;
        }
        ol {
            list-style: lower-roman;
        }
    }
}

.auth-token-alert {
    padding: .5rem 2rem;
    background: yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .message {
        font-size: 1rem;
    }
}

.footer {
    background: $grey-bg;
    padding: .5rem 1rem;
    font-size: .8rem;
    color: $grey-medium;
    position: relative;
    width: 100%;
    z-index: 1;
    height: 35px;
}

.no-map {
    text-align: center;
    background: $grey-bg no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: .8rem;
    svg {
        font-size: 1.75rem;
        margin-bottom: .5rem;
    }
}


.disclaimer {
    height: 50px;
    margin-top: 25px;
    text-align: center;
    font-weight: 300;
    font-size: .5rem;
    div:first-child {
        color: $grey-medium;
        font-style: italic;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div:last-child {
        margin-top: .5em;
        font-weight: 600;
        img {
            margin: 0 .25em;
            display: inline-block;
            height: 1.5em;
            width: 1.5em;
        }
    }
}

::placeholder {
    color: $grey-medium !important;
    font-weight: 300;
    font-style: italic;
    font-size: .9rem;
}

// .modal-open {
//     z-index: 2000;
// }

.clickable {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

a {
    &.black {
        color: black;
    }
    &.dark-grey {
        color: $grey-dark;
    }
}

.brand {
    color: $brand-primary-dark;
}

.main-content {
    @include boxPadding(1rem, 0rem);
    width: 100%;
    .page-title {
        .tag {
            color: $brand-primary-medium;
            background: $brand-primary-dark;
            width: 100px;
            text-align: center;
            border-radius: .25rem;
            font-size: .8rem;
        }
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .header {
            flex: 1 1 auto;
            flex-direction: column;
            h1 {
                line-height: 1.25;
                a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    color: #333;
                }
            }
        }
        .header-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &.active {
                .add {
                    background: $brand-primary-dark;
                    color: white;
                }
            }
            .label {
                font-size: .8rem;
                text-transform: uppercase;
                font-weight: 300;
                color: $grey-medium;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .btn {
            span {
                display: inline-block;
                margin-left: .25em;
                @include respond-to-max('large') {
                    display: none;
                }
            }
        }
    }
    &.presentation {
        padding: 0px 15px;
        &.public {
            padding: 4px 5px 0px 5px;
        }
    }
}

.info {
    position: relative;
    svg {
        margin-left: .33rem;
        color: $grey-medium;
        &:hover {
            cursor: pointer;
            color: $grey-dark;
        }
    }
    .popover {
        &.bs-popover-bottom {
            top: 1em;
        }
        &.bs-popover-top {
            top: auto;
            bottom: 100%;    
        }
        
        min-width: 250px;
    }
}

.popover-body {
    // max-height: 80vh;
    // overflow-y: scroll;
    ol {
        margin-top: .5rem;
        padding-left: 20px;
        li {
            margin-bottom: .5rem;
        }
    }
}

.settings-info {
    position: relative;
    .popover {
        top: 40px;
        left: auto; right: 0;
        min-width: 250px;
    }
    .arrow {
        right: 3px;
    }
}

// override bootstrap border on header
.table {
    font-size: .9rem;
    thead {
        tr {
            th {
                border: none;
            }
        }
    }
}

.table td {
    vertical-align: middle;
}

svg {
    &.greys {
        color: $grey-light;
        &:hover {
            color: $grey-medium;
        }
    }
}

.top-border:before {
    content: '';
    display: block;
    @include topBorder;
    // margin: 0 .25rem;
}

.background-gradient {
    @include backgroundGradient;
}

.notice-box {
    @include backgroundGradient;
    @include boxPadding(1rem, 2rem);
    .header {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        .alert-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $brand-gradient-highlight;
            width: 36px;
            height: 36px;
            border-radius: 2rem;
        }
        h2 {
            flex: 1 1 auto;
            padding-left: .75rem;
            margin: 0;
        }
    }
    .body {
        display: flex;
        align-items: flex-start;
        p {
            flex: 1 1 auto;
            padding-right: 4rem;
        }
        a {
            flex: 0 0 auto;
        }
    }
}

.create-form {
    @include boxShadow;
    @include topBorder;
    @include boxPadding;
}

.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-x: hidden;
    padding: 1rem 0;
    .btn {
        background-color: $brand-primary-dark !important;
        color: white;
        border-radius: 2rem;
        font-weight: 700;
        margin: auto;
        margin-top: 0;
        &:hover {
            background-color: $brand-primary-medium !important;
            color: #2B2B2B !important;
        }
        &.cancel {
            background-color: white !important;
            color: black;
        }
    }
    .group {
        margin-bottom: 1.5rem;
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &:focus-within {
            .form-group {
                border-color: black;
            }

            input {
                &:focus {
                    outline: none;
                    outline-width: 0;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
    .display-input {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        @include flexWidth(20, 25, 33.33, 50, 66.67, 75, 100);
        &.join-right {
            margin-right: 0px;
            padding-right: 0px;
            input {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right-color: transparent;
            }
        }
        &.join-left {
            margin-left: 0px;
            padding-left: 0px;
            @include dropDown;
            select {
                -webkit-border-top-left-radius: 0px;
                -webkit-border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                background-color: $grey-bg;
            }
            input {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
        .form-group {
            margin-bottom: 1.25rem;
            position: relative;
            label {
                font-size: .8rem;
                color: #111111;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        .invalid-feedback {
            text-align: left;
            padding-left: .5rem;
        }
        .calculated,
        .helper-text {
            font-size: .8rem;
            padding: .125rem .25rem .5rem .5rem;
            position: absolute;
            left: 0;
            line-height: .95rem;
            text-align: left;
        }
    }
    &.inline {
        width: 100%;
        align-items: flex-end;
        .display-input {
            flex: 1 1 auto;
        }
        .btn {
            margin-bottom: 1.25rem;
            &.icon-only {
                width: 4em;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    &.was-validated {
        .display-input {
            input[type="date"] {
                margin-right: -1em;
            }
        }
    }
    .large-input {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-basis: 100%;
        .display-input {
            .form-group {
                border: 1px solid #CCC;
                border-radius: 5px;
                flex-basis: 50%;
                position: relative;
                label {
                    position: absolute;
                    left: 0;
                    top: -1.5rem;
                }
                .helper-text {
                    width: 200%;
                }
                .calculated {
                    margin-top: .125rem;
                    color: #808080;
                }
            }
            &.left-half {
                padding-right: 0;
                .form-group {
                    border-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &.right-half {
                padding-left: 0;
                .form-group {
                    border-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 100%;
                    margin-top: 1rem;
                }
                &.display-only {
                    .form-group {
                        select,
                        input {
                            font-size: 1rem;
                            padding-left: 3.75rem;
                            font-weight: 700;
                            &:disabled {
                                background-image: none;
                                background-color: white;
                                color: #000;
                            }
                        }
                        select {
                            &:disabled {
                                padding-left: 1rem;
                            }
                        }
                    }
                }
            }
        }
        &.half {
            flex-basis: 50%;
        }
        .display-input {
            &.large-text {
                .form-group {
                    margin-bottom: 0;
                    margin-top: 1rem;
                    input {
                        border: none;
                        font-size: 2.25rem;
                        font-weight: 300;
                        text-align: left;
                        height: calc(4rem + 3px);
                        &::placeholder {
                            font-size: 1.5rem;
                        }
                    }
                    input[type="date"] {
                        font-size: 1.75rem;
                        padding-top: .6rem;
                    }
                }
            }
            &.large-select {
                .form-group {
                    margin-bottom: .25rem;
                    padding: .5rem .5rem .5rem 2.75rem;
                    select {
                        width: 100%;
                        appearance: none;
                        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
                            linear-gradient(135deg, gray 50%, transparent 50%);
                        background-position: calc(100% - 15px) calc(1em + 8px),
                            calc(100% - 10px) calc(1em + 8px);
                        background-size: 5px 5px, 5px 5px;
                        background-repeat: no-repeat;
                        border: none;
                        margin-top: -1px;
                        background-color: #EFEFEF;
                        border-radius: 5px;
                        height: 3.25rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

#auth-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $brand-primary-medium;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    &.subscription {
        .content {
            z-index: 2;
            width: 50%;
            @include respond-to-max('desktop') {
                width: 70%;
            }
            @include respond-to-max('tablet') {
                width: calc(100vw - 30px);
            }
        }
        justify-content: flex-start;
        margin-top: 3rem;
    }
    a {
        z-index: 1;
        img {
            margin-bottom: 3rem;
        }
    }
    img {
        z-index: 1;
    }
    .content {
        // @include boxShadow;
        @include topBorder;
        background: white;
        padding: 3rem 2rem;
        margin-bottom: 1rem;
    }
    .links {
        margin-top: 2rem;
        p {
            text-align: center;
            font-weight: 300;
            font-size: .8rem;
            color: white;
        }
        a {
            color: white;
        }
    }
}

.search-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // margin-bottom: 2rem;
    .search-wrapper, .display-wrapper, .sort-wrapper {
        margin-left: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .form-group {
        margin-bottom: 0;
    }
    .active-filter {
        border: 1px solid $grey-medium;
        border-radius: 10px;
        padding: .25rem 1rem;
        margin-right: 2rem;
        &:hover {
            cursor: pointer;
        }
    }
}

.hover-zoom {
    transition: all 300ms;
    @include boxShadow;
    &:hover {
        // border-color: $brand-primary-dark !important;
        cursor: pointer;
        // margin-top: -.5rem;
        transform: scale(1.02);
        // box-shadow: $grey-dark 1px 1px 15px;
    }
}

.option-btns {
    display: flex;
    flex-wrap: wrap;
}

.btn {
    text-align: center;
    border-radius: 2rem;
    border: none;
    padding: .45rem 2rem;
    display: inline-block;
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
    &.btn-select {
        border-radius: 4px;
        color: $grey-dark;
        background: $grey-bg;
        margin-right: 1rem;
        margin-bottom: 1rem;
        &:hover {
            background: $grey-light;
        }
        &.active {
            background: $grey-dark;
            color: white;
            &:hover {
                background: $grey-medium;
            }
        }
        
    }
    &.white {
        @include btnSolid($brand-primary-dark, white, white, $brand-gradient-highlight);
    }
    &.blue {
        @include btnSolid(white, $brand-primary-dark, $brand-primary-dark, $brand-primary-medium);
    }
    &.black {
        @include btnSolid(white, $grey-dark, white, $brand-primary-dark);
    }
    &.add {
        @include btnSolid($grey-dark, $grey-bg, white, $brand-primary-dark);
        &.active {
            @include btnSolid(white, $brand-primary-dark, white, $grey-medium);
        }
    }
    &.center {
        display: flex;
        margin: 0 auto;
    }
    &:disabled {
        background: $grey-bg;
        color: $grey-medium;
        &.black {
            background: $grey-dark;
            color: white;
            &:hover {
                background: $grey-dark;
                color: white;
            }
        }
        &:hover {
            cursor: default;
            background: $grey-bg;
            color: $grey-medium
        }
    }
}

.text-blue{
    color: $brand-primary-dark;
}

.sm-txt {
    font-size: .9rem;
    font-weight: 300;
}

// .StripeElement {
//     margin-bottom: 0 !important;
// }

.table-header{
    margin-bottom: -1rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.table-values {
    @include boxShadow;
    @include topBorder;
    .value-set {
        display: flex;
        padding: 1rem;
        align-items: center;
        border-bottom: 1px solid $grey-bg;
        label {
            width: 25%;
            padding: 0;
            margin: 0;
        }
        span {
            font-weight: 700;
        }
        &.highlight{
            margin-left: -15px;
            margin-right: -15px;
            @include backgroundGradient;
        }
    }
}

.key-value {
    display: flex;
    flex-direction: column;
    &.horizontal {
        flex-direction: row;
        justify-content: space-between;
    }
    width: 100%;
    label {
        font-weight: 300;
        font-size: .8rem;
        text-transform: uppercase;
        margin: 0;
    }
}

.geo-code-search {
    position: absolute;
    top: 5px;
    right: 45px;
    input {
        width: 300px;
    }
    .react-geocoder-results {
        background: white;
        width: 300px;
        overflow-x: hidden;
        .react-geocoder-item {
            padding: .5rem .5rem;
            border-bottom: 1px solid;
            &:hover {
                background: #CCC;
                cursor: pointer;
            }
        }
    }
}

.color-base-rent {
    color: $baseRentSolid;
}

.color-additional-rent {
    color: $additionalRentSolid;
}

.color-parking {
    color: $parkingSolid;
}

.color-opex {
    color: $opExSolid;
}

.color-improvements {
    color: $improvementsSolid;
}


.grey-bg {
    background: $grey-bg;
}

.MuiPaper-elevation4 {
    @include boxShadow;
}

.MuiPaper-root {
    border-radius: 0 !important;
    border-top: 4px solid #333 !important;
    @include boxShadow;
}

.MuiButtonBase-root {
    &:hover {
        color: #478dee !important;
    }
}

// these are part of the bootstrap-grid.scss, do we need them?
// @import "../../../node_modules/bootstrap/scss/mixins/breakpoints";
// @import "../../../node_modules/bootstrap/scss/mixins/grid-framework";
// @import "../../../node_modules/bootstrap/scss/mixins/grid";
// @import "../../../node_modules/bootstrap/scss/utilities/display";
// @import "../../../node_modules/bootstrap/scss/utilities/flex";
// @import "../../../node_modules/bootstrap/scss/utilities/spacing";