@import '_colors';

@mixin boxShadow {
    box-shadow: 0px 2px 15px #d7d7d7;
}

@mixin topBorder($borderColor:$grey-dark) {
    border-top: 4px solid $borderColor !important;
}

@mixin boxPadding($topBottom:2rem, $leftRight:4rem) {
    padding: $topBottom $leftRight;
}

@mixin backgroundGradient($first:$brand-gradient-first, $second:$brand-gradient-second) {
    background: $first;
    background: linear-gradient(135deg, $first 0%, $second 100%);
    color: white;
}

@mixin flexWidth($widths...) {
    @each $width in $widths {
        &.flex-#{round($width)} {
            flex-basis: #{percentage(calc($width/100))};
            &:not(.join-left):not(.join-right) {
                flex-basis: calc(#{percentage(calc($width/100))} - .5rem);
            }
        }
    }
}

@mixin hoverZoom() {
    transition: all 300ms;
    &:hover {
        border-color: $brand-primary-dark !important;
        cursor: pointer;
        // margin-top: -.5rem;
        transform: scale(1.02);
        box-shadow: $grey-dark 1px 1px 15px;
    }
}

@mixin btnSolid($color, $background, $hoverColor, $hoverBackground) {
    color: $color;
    background: $background;
    &:hover {
        color: $hoverColor;
        background: $hoverBackground;
    }
}

@mixin btnOutline($color, $hoverColor:white) {
    color: $color;
    background: transparent;
    border: 1px solid $color;
    &:hover {
        color: $hoverColor;
        background: $color;
    }
}

@mixin dropDown {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        // padding: 0 4em 0 1em;
        // border: thin solid #CCC;
        // line-height: 3em;
        // border-radius: 6px;
        background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
          calc(100% - 15px) calc(1em + 2px),
          calc(100% - 10px) calc(1em + 2px);
        background-size:
          5px 5px,
          5px 5px;
        background-repeat: no-repeat;
      }
}

@mixin lineClamp($lines: 2) {
    line-clamp: $lines;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    p {
        margin-bottom: 0;
    }
}