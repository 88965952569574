// $grey-bg: #F7F6F6;
$grey-bg: #f5f5f7;
$grey-light: #DEDEDF;
$grey-medium: #A6A6A7;
$grey-dark: #333333;
$grey-overview: #808080;

$brand-primary-light: #EAF4FD; // blue
$brand-primary-medium: #D8E6F4;
$brand-primary-dark: #478DEE; // blue
$brand-gradient-first: #406BAD;
$brand-gradient-second: #4488E6;
$brand-gradient-highlight: #6491CC;

// $brand-secondary-light: #ffe2de; // orange
$brand-secondary-dark: #ff6e59; // orange

$box-shadow: #A6A6A7;

$scorecard-positive: #478DEE;
$scorecard-negative: #F5B656;
// #9590ED backup color (purple)

$baseRentSolid: rgba(44, 113, 209, 1);
$baseRentFaded: rgba(44, 113, 209, .25);

$additionalRentSolid: rgba(81, 155, 240, 1);
$additionalRentFaded: rgba(81, 155, 240, .25);

$parkingSolid: rgba(148, 213, 227, 1);
$parkingFaded: rgba(148, 213, 227, .25);

$opExSolid: rgba(96, 199, 137, 1);
$opExFaded: rgba(96, 199, 137, .25);

$improvementsSolid: rgba(247, 115, 94, 1);
$improvementsFaded: rgba(247, 115, 94, .25);

$buySellSolid: rgba(94, 49, 158, 1);
$buySellFaded: rgba(94, 49, 158, .25);

$debtSolid: rgba(218, 165, 32, 1);
$debtFaded: rgba(218, 165, 32, .25);

